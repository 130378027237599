import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from './common.service';
import { Message } from '../interfaces/common-interface';
import { KeywordItems } from '../interfaces/load-list';
import { UtilityStatus, StatusResponse, UtilityType, TypeResponse, UtilityOwner, OwnerResponse } from '../interfaces/Utility-locate';
import { EquipmentMapping, EquipmentCheck, EquipmentList } from '../interfaces/employee';
import {
  KeywordsList, KeywordResponse, CategoryList, SourceResponse, SiteActivity, ActivityResponse,
  CategoryResponse, ListCategory, ListResponse, Policy, SourceUrls, ActivityDetails
} from '../interfaces/configuration';
import { BulletinType, BulletinResponse, AnnouncementList, AnnouncementResponse, AnnouncementData } from '../interfaces/bulletin';
import { DefaultList, AssignmentResponse } from '../interfaces/maintenance';
import { PlateTypeList, PlateTypeListWithPagination, PlateTypeResponse, StateListWithPagination, StatesResponse, TireTypeList, TireTypeListWithPagination, TireTypeResponse, stateList } from '../interfaces/config-project';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  KEYWORD_LIST = 'configuration/keywords/list';
  CRUD_KEYWORD = 'configuration/keywords';
  KEYWORD_ITEM = 'aggregation/loadlist/keywords-items';
  FINALIZE_LIST = 'aggregation/loadlist/load-list-finalize';
  CATEGORY_LIST = 'configuration/tasklist-category/list';
  CATEGORY_CRUD = 'configuration/tasklist-category';

  LOAD_LIST = 'aggregation/loadlist-category/list';
  LOAD_LIST_CRUD = 'aggregation/loadlist-category';
  LOAD_LIST_DETAIL = 'aggregation/loadlist-category/details';
  LIST_CATEGORY = 'aggregation/loadlist-category/sub-category';

  POLICY = 'configuration/privacy-policy';

  EQUIPMENT = 'aggregation/equip-mapping';
  EQUIPMENT_LIST = 'aggregation/equip-mapping/fetch';
  CSV_FILE = 'aggregation/loadlist-category/verify-csv';
  UPLOAD_CSV = 'aggregation/loadlist-category/excel-upload';
  DOWNLOAD_CSV = 'aggregation/loadlist-category/excel-download';

  WATER_SOURCE_CRUD = 'configuration/water-source';
  WATER_SOURCE = 'configuration/water-source/list';

  UTILITY_STATUS = 'configuration/locate-status/list';
  UTILITY_CRUD = 'configuration/locate-status';

  SITE_ACTIVITY = 'configuration/activities/list';
  SITE_ACTIVITY_CRUD = 'configuration/activities';
  SITE_SUB_ACTIVITY = 'configuration/activities/sub-activity'

  UTILITY_TYPE = 'configuration/locate-type/list';
  UTILITY_TYPE_CRUD = 'configuration/locate-type';

  UTILITY_OWNER = 'configuration/locate-owner/list';
  UTILITY_OWNER_CRUD = 'configuration/locate-owner';
  FILE_DOWNLOAD = 'configuration/locate-owner/excel-download';
  FILE_UPLOAD = 'configuration/locate-owner/excel-upload';

  BULLETIN_LIST = 'configuration/bulletin-type/list';
  BULLETIN_CRUD = 'configuration/bulletin-type';

  ANNOUNCEMENT = 'aggregation/bullet-in-announcement/list';
  ANNOUNCEMENT_CRUD = 'aggregation/bullet-in-announcement';

  LOCATION = 'aggregation/bullet-in-location/list';
  CHECK_EQUIP = 'aggregation/equip-mapping/check';

  MAP_EQUIP = 'aggregation/equip-mapping/assign-equip';

  GROUP_LIST = 'configuration/default-service/list';
  GROUP_CRUD = 'configuration/default-service';


  STATE = 'configuration/states/list';
  STATE_CRUD = 'configuration/states';

  PLATE = 'configuration/plate-type/list';
  PLATE_CRUD = 'configuration/plate-type';

  TIRE = 'configuration/tire-type/list';
  TIRE_CRUD = 'configuration/tire-type';

  constructor(
    private http: HttpClient,
    private cos: CommonService) {
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patch$ = new BehaviorSubject<any>([]);

  setPatchValue(conflicts) {
    this.patch$.next(conflicts)
  }

  /**
   * Keywords Services
   */
  getKeywordList(search) {
    return this.http.get<KeywordsList>(`${environment.BASE_URL}${this.KEYWORD_LIST}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}&sort=true&type=${search.type}`);
  }

  getKeywordLists(data) {
    return this.http.get<KeywordsList>(`${environment.BASE_URL}${this.KEYWORD_LIST}?pagination=false&sort=true&search=${data.search}&type=${data.type}`);
  }

  getKeyword(type: string) {
    return this.http.get<KeywordsList>(`${environment.BASE_URL}${this.KEYWORD_LIST}?pagination=false&sort=true&type=${type}`);
  }

  createKeyword(data) {
    return this.http.post<KeywordResponse>(environment.BASE_URL + this.CRUD_KEYWORD, data);
  }

  updateKeyword(data) {
    return this.http.put<KeywordResponse>(environment.BASE_URL + this.CRUD_KEYWORD, data);
  }

  deleteKeyword(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<Message>(environment.BASE_URL + this.CRUD_KEYWORD, options);
  }

  /**
   * Load List Category Service
   */
  getLoadItem(ids) {
    return this.http.post<KeywordItems>(environment.BASE_URL + this.KEYWORD_ITEM, ids);
  }

  loadListFinalized(data) {
    return this.http.put<Message>(environment.BASE_URL + this.FINALIZE_LIST, data);
  }

  /**
  * Task List Category Service
  */

  getCategoryList(search) {
    return this.http.get<CategoryList>(`${environment.BASE_URL}${this.CATEGORY_LIST}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}&sort=true`)
  }

  getCategories() {
    return this.http.get<CategoryList>(`${environment.BASE_URL}${this.CATEGORY_LIST}?pagination=false&sort=true`)
  }

  createCategory(data) {
    return this.http.post<CategoryResponse>(environment.BASE_URL + this.CATEGORY_CRUD, data);
  }

  deleteCategory(id) {
    return this.http.delete<Message>(`${environment.BASE_URL}${this.CATEGORY_CRUD}?id=${id}`)
  }

  updateCategory(data) {
    return this.http.put<CategoryResponse>(environment.BASE_URL + this.CATEGORY_CRUD, data);
  }

  /**
   * Load List Category Services
   */
  uploadLoadFile(data) {
    return this.http.post<Message>(environment.BASE_URL + this.UPLOAD_CSV, data);
  }

  downloadLoadFile() {
    return this.http.get<Message>(environment.BASE_URL + this.DOWNLOAD_CSV);
  }

  getList(search) {
    return this.http.get<ListCategory>(`${environment.BASE_URL}${this.LOAD_LIST}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}`)
  }

  loadListCategory() {
    return this.http.get<ListCategory>(`${environment.BASE_URL}${this.LOAD_LIST}?pagination=false`)
  }


  createCategoryList(data) {
    return this.http.post<ListResponse>(environment.BASE_URL + this.LOAD_LIST_CRUD, data);
  }

  deleteList(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<Message>(environment.BASE_URL + this.LOAD_LIST_CRUD, options);
}

  getCategoryDetails(id: string) {
    return this.http.get<ListCategory>(`${environment.BASE_URL}${this.LOAD_LIST_CRUD}?parentId=${id}`)
  }

  getAllCategoryDetails(id: string) {
    return this.http.get<ListCategory>(`${environment.BASE_URL}${this.LOAD_LIST_CRUD}/details?id=${id}`);
  }

  updateCategoryList(data) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.put<any>(environment.BASE_URL + this.LOAD_LIST_CRUD, data);
  }

  getListCategory(id) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<any>(`${environment.BASE_URL}${this.LIST_CATEGORY}?id=${id}`)
  }

  updateSubcategory(data) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.put<any>(environment.BASE_URL + this.LIST_CATEGORY, data);
  }

  /**
   * Policy Service
   */
  updatePolicy(data) {
    return this.http.put<Policy>(environment.BASE_URL + this.POLICY, data);
  }

  addPolicy(data) {
    return this.http.post<Policy>(environment.BASE_URL + this.POLICY, data);
  }
  getPolicy() {
    return this.http.get<Policy>(`${environment.BASE_URL}${this.POLICY}`)
  }

  /**
   * Employee's Equipment Service
   */
  bindEquipment(data) {
    return this.http.post<EquipmentMapping>(environment.BASE_URL + this.EQUIPMENT, data)
      .pipe(catchError(this.cos.handleError));
  }

  mapEquipment(data) {
    return this.http.post<EquipmentMapping>(environment.BASE_URL + this.MAP_EQUIP, data)
      .pipe(catchError(this.cos.handleError));
  }

  checkEquipment(data) {
    return this.http.post<EquipmentCheck>(environment.BASE_URL + this.CHECK_EQUIP, data);
  }

  getEquipment(id) {
    return this.http.get<EquipmentList>(`${environment.BASE_URL}${this.EQUIPMENT_LIST}?pagination=false&employeeId=${id}`);

  }

  deleteEquipment(data) {
    return this.http.put<EquipmentMapping>(environment.BASE_URL + this.EQUIPMENT, data)
  }

  /**
   * Water Source URl Services
   */

  addSourcesURL(data) {
    return this.http.post<SourceResponse>(environment.BASE_URL + this.WATER_SOURCE_CRUD, data);
  }

  getWaterSources(search) {
    return this.http.get<SourceUrls>(`${environment.BASE_URL}${this.WATER_SOURCE}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}`)
  }

  updateSourcesURL(data) {
    return this.http.put<SourceResponse>(environment.BASE_URL + this.WATER_SOURCE_CRUD, data);
  }

  deleteSourcesURL(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<Message>(environment.BASE_URL + this.WATER_SOURCE_CRUD, options);
  }

  /**
   * Utility Status Response
   */
  getUtilityStatus(search) {
    return this.http.get<UtilityStatus>(`${environment.BASE_URL}${this.UTILITY_STATUS}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}`)

  }

  addUtilityStatus(data) {
    return this.http.post<StatusResponse>(environment.BASE_URL + this.UTILITY_CRUD, data);
  }

  updateUtilityStatus(data) {
    return this.http.put<StatusResponse>(environment.BASE_URL + this.UTILITY_CRUD, data);
  }

  deleteUtilitySource(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<Message>(environment.BASE_URL + this.UTILITY_CRUD, options);
  }

  /**
   * Site Activity Services
   * @param search
   * @returns
   */
  getSiteList(search) {
    return this.http.get<SiteActivity>(`${environment.BASE_URL}${this.SITE_ACTIVITY}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}&activity=true&sort=${search.sort}`)
  }

  getSiteActivities() {
    return this.http.get<SiteActivity>(`${environment.BASE_URL}${this.SITE_ACTIVITY}?pagination=false&activity=true&sort=true`)
  }

  addSiteActivity(data) {
    return this.http.post<ActivityResponse>(environment.BASE_URL + this.SITE_ACTIVITY_CRUD, data);
  }

  deleteSiteActivity(id) {
    return this.http.delete<Message>(`${environment.BASE_URL}${this.SITE_ACTIVITY_CRUD}?id=${id}`)
  }

  getSiteDetail(id) {
    return this.http.get<ActivityDetails>(`${environment.BASE_URL}${this.SITE_ACTIVITY}?typeId=${id}`);
  }

  deleteSubActivity(ids) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: ids
    };
    return this.http.delete<Message>(environment.BASE_URL + this.SITE_SUB_ACTIVITY, options);
  }

  updateActivityName(data) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.put<any>(environment.BASE_URL + this.SITE_ACTIVITY_CRUD, data);
  }

  getSubActivityData(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<any>(`${environment.BASE_URL}${this.SITE_ACTIVITY_CRUD}?id=${id}`)
  }

  updateActivity(data) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.post<any>(environment.BASE_URL + this.SITE_SUB_ACTIVITY, data);
  }


  /**
   * Utility Type Service
   */
  getUtilityType(search) {
    return this.http.get<UtilityType>(`${environment.BASE_URL}${this.UTILITY_TYPE}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}`)
  }

  getUtilityTypes() {
    return this.http.get<UtilityType>(`${environment.BASE_URL}${this.UTILITY_TYPE}?pagination=false`)
  }

  addUtilityType(data) {
    return this.http.post<TypeResponse>(environment.BASE_URL + this.UTILITY_TYPE_CRUD, data);
  }

  updateUtilityType(data) {
    return this.http.put<TypeResponse>(environment.BASE_URL + this.UTILITY_TYPE_CRUD, data);
  }

  deleteUtilityType(id) {
    return this.http.delete<Message>(`${environment.BASE_URL}${this.UTILITY_TYPE_CRUD}?id=${id}`)
  }

  /**
   * Utility Owner Service
   */
  getUtilityOwner(search) {
    return this.http.get<UtilityOwner>(`${environment.BASE_URL}${this.UTILITY_OWNER}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}&name=${search.name}&code=${search.code}&state=${search.state}`)
  }

  addUtilityOwner(data) {
    return this.http.post<OwnerResponse>(environment.BASE_URL + this.UTILITY_OWNER_CRUD, data);
  }

  updateUtilityOwner(data) {
    return this.http.put<OwnerResponse>(environment.BASE_URL + this.UTILITY_OWNER_CRUD, data);
  }

  deleteUtilityOwner(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<Message>(environment.BASE_URL + this.UTILITY_OWNER_CRUD, options);

  }

  downloadFile() {
    return this.http.get<Message>(`${environment.BASE_URL}${this.FILE_DOWNLOAD}`);
  }

  uploadFile(data) {
    return this.http.post<Message>(environment.BASE_URL + this.FILE_UPLOAD, data);
  }

  ownerDetails(id) {
    return this.http.get<OwnerResponse>(`${environment.BASE_URL}${this.UTILITY_OWNER_CRUD}?id=${id}`)
  }

  /**
   * Bulletin Type Services
   * @param search
   * @returns
   */
  getBulletinList(search) {
    return this.http.get<BulletinType>(`${environment.BASE_URL}${this.BULLETIN_LIST}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}&sorting=true`)
  }

  getTypeList() {
    return this.http.get<BulletinType>(`${environment.BASE_URL}${this.BULLETIN_LIST}?pagination=false&sorting=true`)

  }

  addBulletin(data) {
    return this.http.post<BulletinResponse>(environment.BASE_URL + this.BULLETIN_CRUD, data);
  }

  updateBulletin(data) {
    return this.http.put<BulletinResponse>(environment.BASE_URL + this.BULLETIN_CRUD, data);
  }

  checkBulletin(data) {
    return this.http.get<BulletinResponse>(`${environment.BASE_URL}${this.BULLETIN_CRUD}/check?id=${JSON.stringify(data)}`);
  }

  deleteBulletin(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<Message>(environment.BASE_URL + this.BULLETIN_CRUD, options);
  }

  /**
   * Announcement Service
   * @param search
   * @returns
   */
  getAnnouncement(search) {
    return this.http.get<AnnouncementList>(`${environment.BASE_URL}${this.ANNOUNCEMENT}?pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}`)
  }


  addAnnouncement(data) {
    return this.http.post<AnnouncementResponse>(environment.BASE_URL + this.ANNOUNCEMENT_CRUD, data);
  }

  updateAnnouncement(data) {
    return this.http.put<AnnouncementResponse>(environment.BASE_URL + this.ANNOUNCEMENT_CRUD, data);
  }

  deleteAnnouncement(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<Message>(environment.BASE_URL + this.ANNOUNCEMENT_CRUD, options);
  }

  getAcnDetails(id: string) {
    return this.http.get<AnnouncementData>(`${environment.BASE_URL}${this.ANNOUNCEMENT_CRUD}?id=${id}`);
  }

  /**
   * Default Assignment Service
   * @param search
   * @returns
   */
  getAssignedList(search) {
    return this.http.get<DefaultList>(`${environment.BASE_URL}${this.GROUP_LIST}?pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}`);
  }

  getDefaultList() {
    return this.http.get<DefaultList>(`${environment.BASE_URL}${this.GROUP_LIST}?pagination=false`);
  }

  deleteAssigned(id: string) {
    return this.http.delete<Message>(`${environment.BASE_URL}${this.GROUP_CRUD}?id=${id}`)

  }

  addDefaultAssigned(data) {
    return this.http.post<AssignmentResponse>(environment.BASE_URL + this.GROUP_CRUD, data);
  }

  UpdateDefaultAssigned(data) {
    return this.http.put<AssignmentResponse>(environment.BASE_URL + this.GROUP_CRUD, data);
  }

  getDefaultAssignDetail(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<any>(`${environment.BASE_URL}${this.GROUP_CRUD}?id=${id}`);
  }

  /**
   * States Service
   */
  stateWithPagination(search) {
    return this.http.get<StateListWithPagination>(`${environment.BASE_URL}${this.STATE}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}`)
  }

  getStates() {
    return this.http.get<stateList>(`${environment.BASE_URL}${this.STATE}?pagination=false`)
  }

  addState(data) {
    return this.http.post<StatesResponse>(environment.BASE_URL + this.STATE_CRUD, data);
  }

  updateState(data) {
    return this.http.put<StatesResponse>(environment.BASE_URL + this.STATE_CRUD, data);
  }

  deleteState(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<Message>(environment.BASE_URL + this.STATE_CRUD, options);
  }

  /**
   * Plate Type Service
   */

  plateTypeWithPagination(search) {
    return this.http.get<PlateTypeListWithPagination>(`${environment.BASE_URL}${this.PLATE}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}`)
  }

  getPlateTypes() {
    return this.http.get<PlateTypeList>(`${environment.BASE_URL}${this.PLATE}?pagination=false`)
  }

  addPlateType(data) {
    return this.http.post<PlateTypeResponse>(environment.BASE_URL + this.PLATE_CRUD, data);
  }

  updatePlateType(data) {
    return this.http.put<PlateTypeResponse>(environment.BASE_URL + this.PLATE_CRUD, data);
  }

  deletePlateType(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<Message>(environment.BASE_URL + this.PLATE_CRUD, options);
  }

  /**
   * Tire Type Service
   */

   tireTypeWithPagination(search) {
    return this.http.get<TireTypeListWithPagination>(`${environment.BASE_URL}${this.TIRE}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}`)
  }

  getTireTypes() {
    return this.http.get<TireTypeList>(`${environment.BASE_URL}${this.TIRE}?pagination=false`)
  }

  addTireType(data) {
    return this.http.post<TireTypeResponse>(environment.BASE_URL + this.TIRE_CRUD, data);
  }

  updateTireType(data) {
    return this.http.put<TireTypeResponse>(environment.BASE_URL + this.TIRE_CRUD, data);
  }

  deleteTireType(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<Message>(environment.BASE_URL + this.TIRE_CRUD, options);
  }

}
