import { Injectable } from '@angular/core';
import { AnnouncementList } from '../interfaces/bulletin';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Message } from '../interfaces/common-interface';
import {
  NotificationList,
  DashboardProject,
  DashboardMaintenance,
  EmployeeCount,
  DashboardSchedule,
  EquipmentCount,
  DashboardSummary,
  DateAndCurrencyResponse,
  NotificationsResponse
} from '../interfaces/dashboard';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  DASHBOARD = 'aggregation/dashboard';
  NOTIFICATIONS = 'aggregation/notifications/list';
  NOTIFICATION_UPDATE = 'aggregation/notifications';


  constructor(
    private http: HttpClient
  ) { }

  notificationCount$ = new BehaviorSubject<string>('');
  updateNotificationCount(reset) {
    this.notificationCount$.next(reset)
  }


  allAnnouncements() {
    return this.http.get<AnnouncementList>(`${environment.BASE_URL}${this.DASHBOARD}/announcements`)
  }

  getMaintenanceList() {
    return this.http.get<DashboardMaintenance>(`${environment.BASE_URL}${this.DASHBOARD}/rm-count`)
  }

  getProjectList() {
    return this.http.get<DashboardProject>(`${environment.BASE_URL}${this.DASHBOARD}/project-count`);
  }

  getEmployeeList() {
    return this.http.get<EmployeeCount>(`${environment.BASE_URL}${this.DASHBOARD}/user-count`);
  }

  getScheduleList() {
    return this.http.get<DashboardSchedule>(`${environment.BASE_URL}${this.DASHBOARD}/schedule-count`);
  }

  getEquipmentList() {
    return this.http.get<EquipmentCount>(`${environment.BASE_URL}${this.DASHBOARD}/equipment-count`);
  }

  getSummaryList() {
    return this.http.get<DashboardSummary>(`${environment.BASE_URL}${this.DASHBOARD}/backlog-summary`);
  }
  allNotifications(search) {
    return this.http.get<NotificationsResponse>(`${environment.BASE_URL}${this.NOTIFICATION_UPDATE}/logs?pagination=${search.pagination}&page=${search.page}&limit=${search.noOfRecord}&broadcast=${search.broadcast}&groups=${JSON.stringify(search.groups)}`);
  }  

  getPushNotification(search) {
    return this.http.get<NotificationList>(`${environment.BASE_URL}${this.NOTIFICATIONS}?pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}`);
  }

  updateNotification(data) {
    return this.http.put<Message>(environment.BASE_URL + this.NOTIFICATION_UPDATE, data);
  }

  sendBroadcast(data) {
    return this.http.post<Message>(environment.BASE_URL + this.NOTIFICATION_UPDATE, data);
  }

  getCurrencyAndDate() {
    return this.http.get<DateAndCurrencyResponse>(`${environment.BASE_URL}${this.DASHBOARD}/date-currency`);

  }


}
