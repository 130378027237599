import { forkJoin } from 'rxjs';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Chart, { ChartConfiguration, ChartTypeRegistry } from 'chart.js/auto';
import { CommonService } from 'src/app/_services/common.service';
import { RepairMaintenanceService } from 'src/app/_services/repair-maintenance.service';
import { DashboardService } from 'src/app/_services/dashboard.service';
import { Router } from '@angular/router';
import { FormDataItem } from "src/app/interfaces/common-interface";
import { DashboardSummaryDetails } from 'src/app/interfaces/project';
import {
  ProjectDashboard,
  DashboardRepair,
  DashboardEmployee,
  ScheduleDashboard,
  DashboardEquipment,
  NotificationsResponse
} from 'src/app/interfaces/dashboard';
import { AuthService } from 'src/app/_services/auth.service';
import { FormBuilder } from '@angular/forms';
import { GroupService } from 'src/app/_services/group.service';
import { FunctionsService } from 'src/app/_common/functions.service';
import { response } from 'src/app/enum/message';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('canvasRef', { static: true }) canvasRef: ElementRef<HTMLCanvasElement>;

  newColor: string[] = [];
  selectedGroupId = '';
  baseColors = ['#FFA41B', '#5D9C59', '#1B9C85'];
  dynamicBaseColor = ['#FFA41B', '#5D9C59', '#1B9C85', '#7c9bb9', '#a47a6a', '#CCCCFF', '#FF00FF', '#2196F3'];
  projectLength = [];
  projectCount = 0;
  employeeLength = [{ title: 'In Project Task', count: 0 }, { title: 'In Schedule', count: 0 }, { title: 'In General Task', count: 0 }];
  repair_maintenance = [{ title: 'High', count: 0 }, { title: 'Medium', count: 0 }, { title: 'Low', count: 0 }];
  employeesData: DashboardEmployee;
  equipmentLength: Equipment[];
  announcement = [];
  notifications = [];
  groups = [];
  currencyDetails;
  currencySymbol: string;
  maintenanceTab: DashboardRepair[];
  projectTab: ProjectDashboard;
  projectCanvasData = [];
  scheduleCount: ScheduleDashboard;
  equipmentCount: DashboardEquipment;
  summaryDetail: DashboardSummaryDetails[];
  summaryCount = [];
  maintenanceCount = 0;
  projectCanvas: HTMLCanvasElement;
  employeeCanvas: HTMLCanvasElement;
  equipmentCanvas: HTMLCanvasElement;
  tooltipElement: HTMLDivElement;
  chart: Chart;
  employeeChart: Chart;
  equipmentChart: Chart;
  groupsData: any;
  completeData = {};
  modalForm = this.formBuilder.group({
    group: [''],
  });
  search = {
    search: '',
    pagination: true,
    page: 1,
    noOfRecord: 10,
    broadcast: true,
    groups: [""],
  };
  formData: FormDataItem[] = [
    {
      title: "Groups*",
      name: "group",
      type: "checkBox",
      multipleValues: [],
      singleSelection: false,
      message: response.en.GROUP_REQUIRED,
    },
  ];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartConfig: ChartConfiguration<keyof ChartTypeRegistry, any[], unknown> = {
    type: 'doughnut',
    data: {
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    },
    options: {
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            label: (context) => {
              const labelIndex = context.dataIndex;
              const label = this.cos.getTitleCase(this.projectLength[labelIndex].title);
              const value = this.chartConfig.data.datasets[0].data[labelIndex];
              return `${label}: ${value}`;
            },
          },
        },
      },
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  equipmentConfig: ChartConfiguration<keyof ChartTypeRegistry, any[], unknown> = {
    type: 'doughnut',
    data: {
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    },
    options: {
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            label: (context) => {
              const labelIndex = context.dataIndex;
              const label = this.cos.getTitleCase(this.equipmentLength[labelIndex].title && this.equipmentLength[labelIndex].title.startsWith("In ") ? this.equipmentLength[labelIndex].title.substring(3) : this.equipmentLength[labelIndex].title);

              const value = this.chartConfig.data.datasets[0].data[labelIndex];
              return `${label}: ${value}`;
            },
          },
        },
      },
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  employeeConfig: ChartConfiguration<keyof ChartTypeRegistry, any[], unknown> = {
    type: 'doughnut',
    data: {
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    },
    options: {
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            label: (context) => {
              const labelIndex = context.dataIndex;
              const label = this.cos.getTitleCase(this.employeeLength[labelIndex].title && this.employeeLength[labelIndex].title.startsWith("In ") ? this.employeeLength[labelIndex].title.substring(3) : this.employeeLength[labelIndex].title);
              const value = this.chartConfig.data.datasets[0].data[labelIndex];
              return `${label}: ${value}`;
            },
          },
        },
      },
    },
  };
  constructor(
    public cos: CommonService,
    private dashBoardSrvc: DashboardService,
    public maintenance: RepairMaintenanceService,
    private router: Router,
    private commonFunction: FunctionsService,
    private groupSrvc: GroupService,
    private authSrvc: AuthService,
    private formBuilder: FormBuilder
  ) {
    this.getData();
    this.getCurrencyType();
    this.authSrvc.getPermissions();
    this.getGroups();
  }

  ngOnInit() {
    this.getDashboardCount();
    this.currencyDetails = JSON.parse(localStorage.getItem('adminCurrency'));
    this.currencySymbol = this.currencyDetails && this.currencyDetails.symbol ? this.currencyDetails.symbol : '$';
  }

  async getDashboardCount() {
    try {
      const { schedule, equipment, employee } = await forkJoin({
        schedule: this.dashBoardSrvc.getScheduleList(),
        equipment: this.dashBoardSrvc.getEquipmentList(),
        employee: this.dashBoardSrvc.getEmployeeList()
      }).toPromise();
      this.scheduleCount = schedule.data;
      this.equipmentCount = equipment.data;
      this.employeesData = employee.data;
      this.equipmentLength = [
        { title: 'In Shop', count: this.equipmentCount?.in_shop_equipments ? this.equipmentCount.in_shop_equipments : 0 },
        { title: 'Assigned', count: this.equipmentCount?.employee_equipments ? this.equipmentCount.employee_equipments : 0 },
        { title: 'In R&M', count: this.equipmentCount?.rm_equipments ? this.equipmentCount.rm_equipments : 0 }
      ];
      this.employeeLength = [
        { title: "In Project's Task", count: this.employeesData?.task_employees ? this.employeesData.task_employees : 0 },
        { title: 'In Schedule', count: this.employeesData?.scheduled_employees ? this.employeesData.scheduled_employees : 0 },
        { title: 'In General Task', count: this.employeesData?.general_task_employees ? this.employeesData.general_task_employees : 0 },
      ];
      this.getEmployeeDetails();
      this.getEquipmentDetails();
    } catch (error) {
      this.cos.handleError(error);
    }
  }

  // This function is used to get the dashboard details
  async getData() {
    try {
      const { maintenance, projects, summary } = await forkJoin({
        maintenance: this.dashBoardSrvc.getMaintenanceList(),
        projects: this.dashBoardSrvc.getProjectList(),
        summary: this.dashBoardSrvc.getSummaryList(),
      }).toPromise();
      this.maintenanceTab = maintenance.data;
      this.projectTab = projects.data;
      this.summaryDetail = summary.data;
      const totalProfit = this.summaryDetail.reduce((total, item) => total + item.profit, 0);
      const totalProfitPercentage = this.summaryDetail.reduce((total, item) => total + parseFloat(item.profitPercentage), 0);
      const totalTotalInvoiced = this.summaryDetail.reduce((total, item) => total + item.totalInvoiced, 0);
      this.summaryCount.push({
        totalInvoiced: `${this.currencySymbol} ${(this.cos.convertToCurrency(totalTotalInvoiced, this.currencyDetails))}`,
        profit: `${this.currencySymbol} ${(this.cos.convertToCurrency(totalProfit, this.currencyDetails))}`,
        profitPercentage: (totalProfitPercentage / this.summaryDetail.length).toFixed(2)
      })

      this.groupsData = {
        title: "Groups*",
        name: "group",
        type: "checkBox",
        multipleValues: this.groups,
        singleSelection: true,
        message: response.en.GROUP_REQUIRED,
      };


      if (this.maintenanceTab) {
        this.maintenanceData(this.maintenanceTab);
      }
      if (this.projectTab) {
        this.projectData(this.projectTab);
      }
    }
    catch (error) {
      this.cos.handleError(error);
    }

  }

  // This function is used to set page limit and range on the list
  public setPagination(data) {
    if (data) {

      this.search.page = data.page;
      this.search.noOfRecord = data.limit; 
      this.getNotifications(this.search);
    }
  }


  /**
   * Function to set Size Ids in ModalForm
   */
  async getGroupIds(data) {
    this.selectedGroupId = data.ids;
    this.search.groups = [this.selectedGroupId];

    await this.getNotifications(this.search);
  }

  async getGroups() {
    try {
      this.groups = [];
      this.groupSrvc.getGroupList().subscribe((res) => {
        if (res.data.length) {
          // Map response to key-value pairs
          this.groups = this.commonFunction.mapResponseToKeyValue(res.data);
  
          // Find the group with isDefault set to true
          const defaultGroup = res.data.find(group => group.isDefault);
  
          // Ensure the dropdown options include the default group
          if (defaultGroup) {
            const defaultGroupOption = this.groups.find(group => group.key === defaultGroup._id);
            if (defaultGroupOption) {
              defaultGroupOption.isDefault = true; 
            }
            
            this.modalForm.patchValue({
              group: defaultGroup._id
            });

            this.search.groups = [defaultGroup._id];
            this.getNotifications(this.search);
          }
        }
      });
    } catch (error) {
      this.cos.handleError(error);
    }
  }
  
  
  async getNotifications(group: any) {
    try {
      this.notifications = [];
      this.dashBoardSrvc.allNotifications(group).subscribe((res: NotificationsResponse) => {
        this.completeData = res;
        if (res.data && res.data.notifications) {
          this.notifications = res.data.notifications.map((item) => {
            return {
              title: item._refId.title || item.title || 'No Title', 
              description: item._refId.name || 'No Description',    
              createdAt: item.createdAt                           
            };
          });
        } else {
          this.notifications = [];
        }
      });
    } catch (error) {
      this.cos.handleError(error);
    }
  }
  

  // This function is used to get details regarding maintenance
  maintenanceData(data) {
    const counts = {
      "LOW": 0,
      "MEDIUM": 0,
      "HIGH": 0,
    };
    data.countByPriority.forEach(({ _id, count }) => {
      counts[_id] = count;
    });

    // Convert counts to the desired format
    this.repair_maintenance = Object.entries(counts).map(([priority, count]) => ({
      title: priority.charAt(0) + priority.slice(1).toLowerCase(),
      count
    }));
    this.maintenanceCount = data ? data.totalCount : 0;
  }

  // This function is used to get details of projects 
  projectData(data) {
    if (data.statusBasedCount) {
      data?.statusBasedCount.map(val => {
        this.projectLength.push({ title: val.status.name, count: val.projectCount });
      });
    }
    this.getProjectDetails();
    this.projectCount = data.totalProjects ? data.totalProjects : 0;
  }

  // This function is used to add canvas for project section
  getProjectDetails() {
    this.projectCanvas = document.getElementById('projectCanvas') as HTMLCanvasElement;
    if (this.projectCanvas) {
      this.chart = new Chart(this.projectCanvas, this.chartConfig);
      const allCountsZero = this.projectLength.every(val => val.count === 0);

      if (allCountsZero) {
        // If all counts are zero, set the data to [0.1] and use a gray color
        this.chart.data.datasets[0].data = [0.1];
        this.chart.data.datasets[0].backgroundColor = 'gray';
      } else {
        // If there are non-zero counts, use the provided data and colors
        this.chart.data.datasets[0].data = this.projectLength.length ? this.projectLength.map(val => val?.count) : [];
        this.chart.data.datasets[0].backgroundColor = this.generateColors(this.dynamicBaseColor, this.projectLength.length);
      }

      this.chart.update();
    }

  }

  // This function is used to add canvas for employee section
  getEmployeeDetails() {
    this.employeeCanvas = document.getElementById('employeeCanvas') as HTMLCanvasElement;
    if (this.employeeCanvas) {
      this.employeeChart = new Chart(this.employeeCanvas, this.employeeConfig);

      const allCountsZero = this.employeeLength.every(val => val.count === 0);

      if (allCountsZero) {
        // If all counts are zero, set the data to [0.1] and use a gray color
        this.employeeChart.data.datasets[0].data = [0.1];
        this.employeeChart.data.datasets[0].backgroundColor = 'gray';
      } else {
        // If there are non-zero counts, use the provided data and colors
        this.employeeChart.data.datasets[0].data = this.employeeLength.map(val => val.count);
        this.employeeChart.data.datasets[0].backgroundColor = this.baseColors;
      }

      this.employeeChart.update();
    }
  }

  // This function is used to add canvas for equipment section
  getEquipmentDetails() {
    this.equipmentCanvas = document.getElementById('equipmentCanvas') as HTMLCanvasElement;
    if (this.equipmentCanvas) {
      this.equipmentChart = new Chart(this.equipmentCanvas, this.equipmentConfig);

      const allCountsZero = this.equipmentLength.every(val => val.count === 0);

      if (allCountsZero) {
        // If all counts are zero, set the data to [0.1] and use a gray color
        this.equipmentChart.data.datasets[0].data = [0.1];
        this.equipmentChart.data.datasets[0].backgroundColor = 'gray';
      } else {
        // If there are non-zero counts, use the provided data and colors
        this.equipmentChart.data.datasets[0].data = this.equipmentLength && this.equipmentLength.map(val => val.count);
        this.equipmentChart.data.datasets[0].backgroundColor = this.baseColors;
      }

      this.equipmentChart.update();
    }
  }

  // This function is used to get the dynamic color
  generateColors(stringsArray: string[], count: number): string[] {
    const step = 360 / count;
    const newColors: string[] = [];

    for (let i = 0; i < count; i++) {
      const hue = (i * step) % 360;
      const color = `hsl(${hue}, 100%, 50%)`;
      newColors.push(color);
    }
    stringsArray = [...new Set(stringsArray)];
    this.newColor = stringsArray.concat(newColors.slice(0, newColors.length - stringsArray.length));
    return this.newColor;

  }

  // Function used to redirect dashboard to notification section
  redirectDashboard(type: string) {
    const typeToUrlMap = {
      project: 'project/projects',
      employee: 'users/employees',
      equipment: 'equipments',
      maintenance: 'maintenance/list',
      schedule: '/schedule/job',
      myTask: 'general-task-list',
    };

    // Use the type-to-url mapping to get the destination URL
    const destinationUrl = typeToUrlMap[type] || 'broadcast-notification';

    this.router.navigateByUrl(destinationUrl);
  }


  ngOnDestroy() {
    this.destroyChart(this.projectLength, this.chart);
    this.destroyChart(this.employeeLength, this.employeeChart);
    this.destroyChart(this.equipmentLength, this.equipmentChart);
  }


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private destroyChart(dataArray: any[], chartInstance: any) {
    if (dataArray && dataArray.length && chartInstance) {
      chartInstance.destroy();
    }
  }

  // function to set currency type and date format in local storage
  getCurrencyType() {
    const isCurrencyExist = localStorage.getItem('adminCurrency');
    const isDateFormatExist = localStorage.getItem('adminDateFormat');
    if (!isCurrencyExist && !isDateFormatExist) {
      this.dashBoardSrvc.getCurrencyAndDate().subscribe((res) => {
        const result = res.data;
        if (result) {
          localStorage.setItem('adminCurrency', JSON.stringify(result.currencyFormat));
          localStorage.setItem('adminDateFormat', JSON.stringify(result.dateFormat));
        }
      });
      this.currencyDetails = JSON.parse(localStorage.getItem('adminCurrency'));
      this.currencySymbol = this.currencyDetails && this.currencyDetails.symbol ? this.currencyDetails.symbol : '$';
    }
  }
}

interface Equipment {
  title: string;
  count: number
}
