import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-estimates-dynamic-dropdown-table',
  templateUrl: './estimates-dynamic-dropdown-table.component.html',
  styleUrls: ['./estimates-dynamic-dropdown-table.component.scss']
})
export class EstimatesDynamicDropdownTableComponent {

  @Input() tableHeading = '';
  @Input() columns = [];
  @Input() dataList = [];
  @Input() labor = false;
  @Input() mobe = false;

  @Output() save = new EventEmitter<string>();
  @Output() fetchUpdatedCost = new EventEmitter<void>();

  updateRecord(action: string): void {
    this.save.emit(action);
  }

  toggleRowVisibility(index: number): void {
    this.dataList[index].expanded = !this.dataList[index].expanded;
  }

  onFetchUpdatedCost(): void {
    this.fetchUpdatedCost.emit();
  }
}