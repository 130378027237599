import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';
import { Message } from '../interfaces/common-interface';
import { UploadDocument, Login, AuthResponse, ExpiredDocumentList, ExpiredDocuments, EmployeeDocsData } from '../interfaces/auth';
import {
  EmployeeDocument, UpdateDocument, EmployeeDetail,
  EmployeeStatus, EmployeeReplace, EmployeeSingleInfo
} from '../interfaces/employee';
import { Skill, SkillResponse } from '../interfaces/skill';
import { Restriction, RestrictionResponse } from '../interfaces/restriction';
import { TravelAccounts, TravelResponse } from '../interfaces/travel-account';
import { EmployeeDocuments, DocumentDetail } from '../interfaces/employee-document';
import { ProjectType, ProjectTypeResponse, ProjectStatus, ProjectStatusData, ProjectStatusResponse } from '../interfaces/config-project';
import { AccessControlService } from './access-control.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  LOGIN_URL = 'api/auth/login';
  LOGOUT = 'api/auth/logout'
  SEND_MAIL = 'aggregation/mail';
  EMPLOYEE = 'api/user/employee';
  EMPLOYEE_ALL = 'api/user/employee/all-employee';
  EMPLOYEE_COUNT = 'api/user/employee/count';
  EMPLOYEE_APPROVAL = 'api/user/employee/approval';
  EMPLOYEE_MAPPING = 'aggregation/employeeMapping';
  FORGOT_PASSWORD = 'api/user-auth/resetPasswordLink';
  RESET_PASSWORD = 'api/user-auth/resetPasswordLink/update';
  CHANGE_PASSWORD = 'api/user-auth/change-password'
  GET_CLIENTCOUNT = 'api/client/get-client-count';
  DOCS_STATUS = 'aggregation/my-document';
  EMP_DETAILS = 'api/user/employee/details';

  SKILL_DATA = 'configuration/employee-skills/list';
  RESTRICTION_DATA = 'configuration/employee-restrictions/list';
  ACCOUNT_DATA = 'configuration/travel-account/list';
  CHANGE_STATUS = 'aggregation/user/employee-detail';
  UPDATE_STATUS = 'aggregation/user/update-schedulers';
  CONVERT_URL = 'aggregation/my-document/convert-url';
  TASK_UPDATE = 'aggregation/user/update-tasks';
  ADD_SKILL = 'configuration/employee-skills';
  ADD_RESTRICTION = 'configuration/employee-restrictions';
  ADD_TRAVEL = 'configuration/travel-account';
  DOCUMENT_TYPE = 'configuration/document-type/list';
  CREATE_DOC = 'configuration/document-type';
  SUB_DOCUMENT = 'configuration/document-type/sub-doc';
  PROJECT_TYPE_LIST = 'configuration/project-type/list';
  PROJECT_CRUD = 'configuration/project-type';
  PROJECT_STATUS = 'configuration/project-status/list';
  PROJECT_STATUS_CRUD = 'configuration/project-status';
  STATUS_ID = 'configuration/project-status/visible-status';
  PROJECT_SUBSTATUS = 'configuration/project-status/sub-status';
  PERDIEM_EMPLOYEE = 'aggregation/per-diem/employee-list';
  PERMISSION = 'api/role/fetch-permissions';
  ADD_DOCUMENT = 'aggregation/my-document';
  PROJECT = 'aggregation/project';
  DOCS = 'aggregation/my-document/employee-docs';
  ALL_EMPLOYEES = 'api/user/all';
  ENABLE_2FA = 'api/user-auth/enable-2fa';
  OTP_VERIFY = 'mfa/otp/verify';
  OTP_RESEND = 'mfa/otp/resend';
  DISABLE_2FA = 'api/user-auth/disable-2fa';
  CHECK_2FA = 'api/auth/check-2fa';
  GENERATE_SECURITY = 'mfa/otp/generate';
  PROFILE = 'api/user-auth/update-user-email';
  USER_DETAILS = 'api/user/fetch-user';
  TRUST_DEVICE = 'api/trust-device/enable';
  CHECK_TRUST = 'api/auth/check-device';

  UPLOAD_EXCEL = 'api/user-excel';
  EXPIRATION_DOCS = 'aggregation/my-document';

  CREATE_EMPLOYEE = 'api/user-task';
  CHANGE_EMPLOYEE_PASSWORD = 'api/user-auth/admin-change-password';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private currentUserSubject: BehaviorSubject<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public currentUser: Observable<any>;

  constructor(
    private http: HttpClient,
    private aclService: AccessControlService,
    private cos: CommonService
  ) {
    this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  /**
   * Auth Services
   */
  forgotPassword(email) {
    return this.http.get<Message>(`${environment.BASE_URL}${this.FORGOT_PASSWORD}?email=${email}&role=ADMIN`);
  }

  resetPassword(data) {
    return this.http.post<Message>(`${environment.BASE_URL}${this.RESET_PASSWORD}`, data);
  }

  changePassword(data) {
    return this.http.put<Message>(`${environment.BASE_URL}${this.CHANGE_PASSWORD}`, data);
  }

  login(body) {
    return this.http.post<Login>(environment.BASE_URL + this.LOGIN_URL, body)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.data.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('authen@User', JSON.stringify(user.data));
          this.currentUserSubject.next(user.data);

          // Access control
          this.aclService.permissions.next({
            fetched: true,
            resources: user.metaData.resources.resources || []
          });
          this.getPermissions();
          localStorage.setItem('role', `${user.metaData.resources.name}`); // To display the role in header

        }
        return user;
      }));
  }

  logout(data) {

    // Get the token from storage
    const storedData = JSON.parse(localStorage.getItem('authen@User'));
    const token = storedData.token;

    // Set up headers with the token
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    localStorage.removeItem('authen@User')
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return this.http.post<Message>(`${environment.BASE_URL}${this.LOGOUT}`, data, { headers });
  }

  getPermissions() {
    this.http.get<PermissionsResponse>(`${environment.BASE_URL}${this.PERMISSION}`).subscribe((res) => {
      if (res) {
        localStorage.setItem('role', `${res.data.name}`); // To display the role in header
        this.aclService.permissions.next({
          fetched: true,
          resources: res.data.resources || []
        });

      }
    }, (err) => { this.cos.handleError(err); });
  }

  enableSecurity() {
    return this.http.post<AuthResponse>(environment.BASE_URL + this.ENABLE_2FA, {});
  }

  updateProfile(data) {
    return this.http.put<AuthResponse>(environment.BASE_URL + this.PROFILE, data);
  }

  generateCode(data) {
    return this.http.post<AuthResponse>(environment.BASE_URL + this.GENERATE_SECURITY, data);
  }

  disableSecurity(data) {
    return this.http.post<AuthResponse>(environment.BASE_URL + this.DISABLE_2FA, data);
  }

  checkSecurity(data) {
    return this.http.post<AuthResponse>(environment.BASE_URL + this.CHECK_2FA, data);
  }

  securityVerify(data) {
    return this.http.post<AuthResponse>(environment.BASE_URL + this.OTP_VERIFY, data);
  }

  resendOtp(data) {
    return this.http.post<AuthResponse>(environment.BASE_URL + this.OTP_RESEND, data);
  }

  checkTrust(data) {
    return this.http.post<AuthResponse>(environment.BASE_URL + this.CHECK_TRUST, data);
  }
  enableTrust(data) {
    return this.http.post<AuthResponse>(environment.BASE_URL + this.TRUST_DEVICE, data);

  }

  /**
   *
   *  Employee Services
   */
  getAllEmployees() {
    return this.http.get<EmployeeDetail>(`${environment.BASE_URL}${this.ALL_EMPLOYEES}`);
  }

  getEmployeeInfo(data) {
    return this.http.get<EmployeeSingleInfo>(`${environment.BASE_URL}${this.EMP_DETAILS}?id=${data}`);
  }

  getEmployeeDetail() {
    return this.http.get<EmployeeSingleInfo>(`${environment.BASE_URL}${this.EMP_DETAILS}`);
  }

  getEmployeeDocs(data) {
    return this.http.get<EmployeeDocument>(`${environment.BASE_URL}${this.DOCS}?employeeId=${data}`);
  }

  deleteEmployeeDocs(data) {
    return this.http.delete<EmployeeDocument>(`${environment.BASE_URL}${this.DOCS}?employeeId=${data}`);
  }

  sendEmail(data) {
    return this.http.post<Message>(environment.BASE_URL + this.SEND_MAIL, data);
  }

  uploadFiles(File,) {
    return this.http
      .post<UploadDocument>(`${environment.BASE_URL}upload/`, File);
  }

  changeStatus(data) {
    return this.http.put<UpdateDocument>(`${environment.BASE_URL}${this.DOCS_STATUS}`, data)
      .pipe(catchError(this.cos.handleError));
  }

  getEmployee(search) {
    return this.http.get<EmployeeDetail>(`${environment.BASE_URL}${this.EMPLOYEE}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}`);
  }

  getALLEmployee(search) {
    return this.http.get<EmployeeDetail>(`${environment.BASE_URL}${this.EMPLOYEE_ALL}?pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}&sort=${search.sort}`);
  }

  getALLEmployees() {
    return this.http.get<EmployeeDetail>(`${environment.BASE_URL}${this.EMPLOYEE}?pagination=false&active=true`);
  }

  getActiveEmployee() {
    return this.http.get<EmployeeDetail>(`${environment.BASE_URL}${this.EMPLOYEE}?pagination=false&active=true&sort=true`);
  }

  getVendorActiveEmployee() {
    return this.http.get<EmployeeDetail>(`${environment.BASE_URL}${this.EMPLOYEE}?pagination=false&active=true&sort=true&includeAdmin=true`);
  }


  getPerDiemEmployees() {
    return this.http.get<EmployeeDetail>(`${environment.BASE_URL}${this.EMPLOYEE}?pagination=false&active=true&sort=true`);

  }

  getFilteredEmployee(data) {
    return this.http.get<EmployeeDetail>(`${environment.BASE_URL}${this.EMPLOYEE}?pagination=false&active=true&sort=true&search=${data}`);
  }

  deleteEmployee(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };

    return this.http.delete<Message>(environment.BASE_URL + this.EMPLOYEE, options)
      .pipe(catchError(this.cos.handleError));
  }

  addEmployee(data) {
    return this.http.post<Message>(environment.BASE_URL + this.EMPLOYEE, data)
      .pipe(catchError(this.cos.handleError))
  }

  createEmployee(data) {
    return this.http.post<Message>(environment.BASE_URL + this.CREATE_EMPLOYEE, data);
  }
  addExpiredDocTask(data) {
    return this.http.put<Message>(environment.BASE_URL + this.CREATE_EMPLOYEE, data);
  }

  updateEmployee(data) {
    return this.http.put<Message>(environment.BASE_URL + this.EMPLOYEE, data)
      .pipe(catchError(this.cos.handleError));
  }

  employeeApproval(data) {
    return this.http.put<Message>(environment.BASE_URL + this.EMPLOYEE_APPROVAL, data)
      .pipe(catchError(this.cos.handleError));
  }

  getEmployeeStatus(data, date) {
    return this.http.get<EmployeeStatus>(`${environment.BASE_URL}${this.CHANGE_STATUS}?id=${data}&date=${date}`);
  }

  updateEmployeeStatus(data) {
    return this.http.post<EmployeeReplace>(environment.BASE_URL + this.UPDATE_STATUS, data)
      .pipe(catchError(this.cos.handleError));
  }

  updateTask(data) {
    return this.http.post<EmployeeReplace>(environment.BASE_URL + this.TASK_UPDATE, data)
  }

  downloadFile(data) {
    return this.http.post<Message>(environment.BASE_URL + this.CONVERT_URL, data);
  }

  downloadExcelFile() {
    return this.http.get<Message>(`${environment.BASE_URL}${this.UPLOAD_EXCEL}/download`);
  }

  uploadFile(data) {
    return this.http.post<Message>(`${environment.BASE_URL + this.UPLOAD_EXCEL}/upload`, data);
  }

  /**
   *
   * Skill Services
   */
  getSkillsData(search) {
    return this.http.get<Skill>(`${environment.BASE_URL}${this.SKILL_DATA}?search=${search.search}&pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}`);
  }

  addSkillData(data) {
    return this.http.post<SkillResponse>(environment.BASE_URL + this.ADD_SKILL, data)
  }

  editSkillData(data) {
    return this.http.put<SkillResponse>(environment.BASE_URL + this.ADD_SKILL, data)
  }

  deleteSkillData(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<Message>(environment.BASE_URL + this.ADD_SKILL, options);
  }

  /**
   *
   * Restriction Services
   */
  getRestrictData(search) {
    return this.http.get<Restriction>(`${environment.BASE_URL}${this.RESTRICTION_DATA}?search=${search.search}&pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}`);
  }

  allRestrictData() {
    return this.http.get<Restriction>(`${environment.BASE_URL}${this.RESTRICTION_DATA}`);
  }

  addRestrictData(data) {
    return this.http.post<RestrictionResponse>(environment.BASE_URL + this.ADD_RESTRICTION, data)
  }

  editRestrictionData(data) {
    return this.http.put<RestrictionResponse>(environment.BASE_URL + this.ADD_RESTRICTION, data)
  }

  deleteRestrictionData(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<Message>(environment.BASE_URL + this.ADD_RESTRICTION, options);
  }

  restrictionData(id) {
    return this.http.get<RestrictionResponse>(`${environment.BASE_URL}${this.ADD_RESTRICTION}?id=${id}`);
  }


  /**
  *
  * TravelAccount Services
  */
  getTravelAccount(search) {
    return this.http.get<TravelAccounts>(`${environment.BASE_URL}${this.ACCOUNT_DATA}?search=${search.search}&pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}`);
  }

  addTravelData(data) {
    return this.http.post<TravelResponse>(environment.BASE_URL + this.ADD_TRAVEL, data);
  }

  editTravelData(data) {
    return this.http.put<TravelResponse>(environment.BASE_URL + this.ADD_TRAVEL, data);
  }

  deleteAccountData(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<Message>(environment.BASE_URL + this.ADD_TRAVEL, options);
  }

  /*
  * Documents Services
  */
  getDocumentType(search, type) {
    return this.http.get<EmployeeDocuments>(`${environment.BASE_URL}${this.DOCUMENT_TYPE}?search=${search.search}&pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}&type=${search.type}&isDocType=${type}`);
  }

  getDocumentList(type) {
    return this.http.get<EmployeeDocuments>(`${environment.BASE_URL}${this.DOCUMENT_TYPE}?pagination=false&isDocType=true}`);
  }

  getDocList() {
    return this.http.get<EmployeeDocuments>(`${environment.BASE_URL}${this.DOCUMENT_TYPE}?pagination=false&isDocType=true`);
  }

  createDocumentType(data) {
    return this.http.post<Message>(environment.BASE_URL + this.CREATE_DOC, data);
  }

  updateField(data) {
    return this.http.put<EmployeeDocument>(environment.BASE_URL + this.CREATE_DOC, data);
  }

  deleteDocument(id: string, id2: string) {
    return this.http.delete<Message>(`${environment.BASE_URL}${this.CREATE_DOC}?id=${id}&fieldId=${id2}`);
  }

  deleteDocType(ids) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: ids
    };
    return this.http.delete<Message>(environment.BASE_URL + this.SUB_DOCUMENT, options);
  }

  getDocumentDetail(search) {
    return this.http.get<DocumentDetail>(`${environment.BASE_URL}${this.DOCUMENT_TYPE}?pagination=false&typeId=${search.typeId}&selfDetail=true`);
  }

  getDocumentDetails(typeId: string) {
    return this.http.get<DocumentDetail>(`${environment.BASE_URL}${this.DOCUMENT_TYPE}?pagination=false&typeId=${typeId}&selfDetail=true`);
  }

  addDocumentType(data) {
    return this.http.post<Message>(environment.BASE_URL + this.SUB_DOCUMENT, data);
  }

  /**
   *  Project Type Services
   */
  getProjectList(search) {
    return this.http.get<ProjectType>(`${environment.BASE_URL}${this.PROJECT_TYPE_LIST}?search=${search.search}&pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}&sort=true`);
  }

  getProjectType() {
    return this.http.get<ProjectType>(`${environment.BASE_URL}${this.PROJECT_TYPE_LIST}?pagination=false&sort=true`);

  }

  deleteProjectType(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<Message>(environment.BASE_URL + this.PROJECT_CRUD, options);
  }

  createProject(data) {
    return this.http.post<ProjectTypeResponse>(environment.BASE_URL + this.PROJECT_CRUD, data);
  }

  updateProject(data) {
    return this.http.put<ProjectTypeResponse>(environment.BASE_URL + this.PROJECT_CRUD, data);
  }

  /**
  *  Project Service Services
  */
  getProjectStatus(search) {
    return this.http.get<ProjectStatus>(`${environment.BASE_URL}${this.PROJECT_STATUS}?search=${search.search}&pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}&status=true`);
  }

  getStatus() {
    return this.http.get<ProjectStatus>(`${environment.BASE_URL}${this.PROJECT_STATUS}?pagination=false&status=true`);
  }

  getProjectsStatus() {
    return this.http.get<ProjectStatus>(`${environment.BASE_URL}${this.PROJECT_STATUS}?pagination=false&status=true&sort=true`);
  }

  createProjectStatus(data) {
    return this.http.post<Message>(environment.BASE_URL + this.PROJECT_STATUS_CRUD, data);
  }

  deleteStatus(id) {
    return this.http.delete<Message>(`${environment.BASE_URL}${this.PROJECT_STATUS_CRUD}?id=${id}`);
  }

  getStatusDetail(id) {
    return this.http.get<ProjectStatusData>(`${environment.BASE_URL}${this.PROJECT_STATUS}?typeId=${id}`);
  }

  updateStatus(data) {
    return this.http.put<ProjectStatusResponse>(environment.BASE_URL + this.PROJECT_STATUS_CRUD, data);
  }

  updateSubStatus(data) {
    return this.http.post<ProjectStatusResponse>(environment.BASE_URL + this.PROJECT_SUBSTATUS, data);
  }

  updateStatusName(data) {
    return this.http.put<ProjectStatusResponse>(environment.BASE_URL + this.PROJECT_SUBSTATUS, data);
  }

  deleteSubStatus(ids) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: ids
    };
    return this.http.delete<Message>(environment.BASE_URL + this.PROJECT_SUBSTATUS, options);
  }

  addDocument(data) {
    return this.http.post<EmployeeDocsData>(environment.BASE_URL + this.ADD_DOCUMENT, data);
  }

  deleteDocuments(ids) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: ids
    };
    return this.http.delete<Message>(environment.BASE_URL + this.ADD_DOCUMENT, options);
  }

  /**
   * Services of Expiration Documents
   */
  expirationList(search) {
    return this.http.get<ExpiredDocumentList>(`${environment.BASE_URL}${this.EXPIRATION_DOCS}/expirations-list?startDate=${search.startDate}&endDate=${search.endDate}&search=${search.search}&type=${JSON.stringify(search.type)}`);
  }

  employeeExpirationList(search) {
    return this.http.get<ExpiredDocuments>(`${environment.BASE_URL}${this.EXPIRATION_DOCS}/expirations-docs?startDate=${search.startDate}&endDate=${search.endDate}&pagination=${search.pagination}&search=${search.search}&page=${search.pageNumber}&limit=${search.noOfRecord}&type=${JSON.stringify(search.type)}`);
  }
  empDocumentDetail(id: string) {
    return this.http.get<EmployeeDocsData>(`${environment.BASE_URL}${this.EXPIRATION_DOCS}?id=${id}`)
  }

  deleteEmpDocument(id: string) {
    return this.http.delete<Message>(`${environment.BASE_URL}${this.EXPIRATION_DOCS}?id=${id}`)
  }

  /**
   * Request to Change Employee Password
   *
   * @param {object} data - Request Body
   */
  changeEmployeePassword(data) {
    return this.http.put<Message>(`${environment.BASE_URL}${this.CHANGE_EMPLOYEE_PASSWORD}`, data);
  }


}


/**
 * Interface
 */
interface PermissionsResponse {
  data: {
    name: string;
    resources: string[];
  };
}

