import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-estimates-consumables-table',
  templateUrl: './estimates-consumables-table.component.html',
  styleUrls: ['./estimates-consumables-table.component.scss']
})
export class EstimatesConsumablesTableComponent {
  @Input() orderList: any[] = [];
  @Input() columns: any[] = [];
  @Input() options: string[] = ['day', 'week'];
  @Input() isConsumables = false; 
  @Input() isRentals = false;

  @Output() save = new EventEmitter<string>();
  @Output() fetchUpdatedCost = new EventEmitter<void>();
  @Output() toggleChange = new EventEmitter<{index: number, value: boolean}>();

  totalCost = 0;

  constructor(private spinner: NgxSpinnerService) {}

  columnVisible(columnName: string): boolean {
    return this.columns.some(column => column.name === columnName);
  }

  calculateTotalCost() {
    this.totalCost = this.orderList.reduce((total, item) => total + (item.totalCost || 0), 0);
  }

  updateRecord(action: string) {
    this.save.emit(action);
  }

  onFetchUpdatedCost() {
    this.fetchUpdatedCost.emit();
  }

  onToggleChange(event: any, index: number) {
    this.orderList[index].isFuelRequired = event.checked;
    this.toggleChange.emit({ index, value: event.checked });
  }

  setListData(event: string, type: string, index: number) {
    if (type === 'cost') {
      this.orderList[index].cost = +event;
    } else if (type === 'quantity') {
      this.orderList[index].quantity = +event || 0;
    }

    if (this.orderList[index].quantity > 0) {
      this.orderList[index].totalCost = this.orderList[index].cost * this.orderList[index].quantity;
    }

    this.calculateTotalCost();
  }
}
