<div class="root-container">
  <div class="table-responsive my-2 tableEffect">
    <table class="table rounded-3">
      <thead class="thead bg-header fixedHeading pl-2 ad-text-size">
        <tr class="estimate-header">
          <th [attr.colspan]="columns.length">{{ tableHeading }}</th>
        </tr>
        <tr>
          <th *ngFor="let column of columns">
            {{ column.displayName }}
          </th>
        </tr>
      </thead>
      <tbody class="ad-text-size">
        <ng-container *ngFor="let row of dataList; let i = index">
          <tr [ngClass]="{'row-bg': row.expanded}">
            <th scope="row" class="permissions-container">
              <div class="permissions-cell" (click)="toggleRowVisibility(i)">
                <span class="arrow" [ngClass]="{'arrow-down': row.expanded, 'arrow-right': !row.expanded}"></span>
                <span class="permissions-text">{{ row.permissions }}</span>
              </div>
            </th>
            <td *ngFor="let field of columns.slice(1)">
              {{ row[field.name] }}
            </td>
          </tr>

        <!-- Expanded row content -->
        <tr *ngIf="row.expanded">
          <td [attr.colspan]="columns.length">
            <table class="table mb-0">
              <tbody>
                <tr *ngFor="let subRow of row.subRows" class="dynamic-row">
                  <th scope="row">
                    <span class="permissions-text">{{ subRow.permissions }}</span>
                  </th>

                  <ng-container *ngIf="labor">
                    <td *ngFor="let field of columns.slice(1)">
                      {{ subRow[field.name] }}
                    </td>
                  </ng-container>

                  <ng-container *ngIf="mobe">
                    <td *ngFor="let field of columns.slice(1)">
                      <input min="0" matInput class="ad-text-size text-center input-field" type="number" [(ngModel)]="subRow[field.name]"
                        maxlength="3" />
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        </ng-container>
      </tbody>
    </table>
  </div>
  <div>
    <button class="btn btn-primary float-right mx-3 my-4 margin-bottom" (click)="updateRecord('SAVE')">
      Save
    </button>
    <button class="btn btn-primary float-right mx-3 my-4 margin-bottom" (click)="updateRecord('SAVE&BACK')">
      Save & Back
    </button>
    <button class="btn btn-primary float-right mx-3 my-4 margin-bottom" (click)="onFetchUpdatedCost()">
      Fetch Updated Cost
    </button>
  </div>
</div>
