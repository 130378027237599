<app-header></app-header>
<div id="wrapper">
  <div class="left side-menu">
    <app-sidebar></app-sidebar>
  </div>
  <div class="all-content-wrapper">
    <div class="sparkline13-list">
      <div class="row mt-4">
        <div class="row header-tab">
          <!-- First Row -->
          <div class="d-flex" id="first-row">
            <!-- Project tab  -->
            <div class="d-flex flex-column col-lg-4 col-md-12" id="tabs">
              <div class="header">
                <h4>Projects</h4>
              </div>

              <div class="canvas">
                <div
                  class="row cursor-pointer"
                  (click)="redirectDashboard('project')"
                >
                  <div class="canvasIcon col-6">
                    <canvas id="projectCanvas"></canvas>
                  </div>
                  <div class="col-6 pt-4">
                    <img src="../../../assets/images/project.png" width="30%" />
                    <div class="textField">Total Projects</div>
                    <div class="count">{{ projectCount }}</div>
                  </div>
                </div>

                <!-- project content  -->
                <div class="mt-3 content mx-2">
                  <ng-container *ngIf="projectLength.length">
                    <div
                      class="d-flex justify-content-between px-2 project-content"
                      *ngFor="let data of projectLength; let i = index"
                    >
                      <div>
                        <span
                          class="color-box"
                          [style.background-color]="
                            newColor[i % newColor.length]
                          "
                        ></span>
                        <span class="content-text textField ml-1">
                          {{ data.title }}</span
                        >
                      </div>
                      <div class="textField pt-2">{{ data.count }}</div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!projectLength.length">
                    <div class="text-center d-flex flex-column">
                      <img
                        src="../../../../../../assets/images/no_data.png"
                        width="12%"
                        class="mt-2 align-self-center"
                      />
                      <span class="mt-2 mb-1" style="color: #707070"
                        >No Projects</span
                      >
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <!-- Employee tab  -->
            <div class="d-flex flex-column col-lg-4 col-md-12" id="tabs">
              <div class="header">
                <h4>Employees</h4>
              </div>

              <div class="canvas">
                <div
                  class="row cursor-pointer"
                  (click)="redirectDashboard('employee')"
                >
                  <div class="canvasIcon col-6">
                    <canvas id="employeeCanvas"></canvas>
                  </div>
                  <div class="col-6 pt-4">
                    <img
                      src="../../../assets/images/employees.png"
                      width="35%"
                    />
                    <div class="textField">Total Employees</div>
                    <div class="count">
                      {{
                        employeesData?.total_employees
                          ? employeesData?.total_employees
                          : 0
                      }}
                    </div>
                  </div>
                </div>

                <!-- employee content  -->
                <div class="d-flex flex-wrap mx-2 pt-3 mt-1">
                  <div
                    *ngFor="let data of employeeLength; let i = index"
                    class="d-flex w-100 justify-content-between"
                  >
                    <div>
                      <span
                        class="color-box"
                        [style.background-color]="
                          baseColors[i % baseColors.length]
                        "
                      ></span>
                      <span class="textField ml-1">{{ data.title }}</span>
                    </div>
                    <div style="margin-top: 6px" class="textField">
                      {{ data.count }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Equipment tab -->
            <div class="d-flex flex-column col-lg-4 col-md-12" id="tabs">
              <div class="header">
                <h4>Equipment</h4>
              </div>

              <div class="canvas">
                <div
                  class="row cursor-pointer"
                  (click)="redirectDashboard('equipment')"
                >
                  <div class="canvasIcon col-6">
                    <canvas id="equipmentCanvas"></canvas>
                  </div>

                  <div class="pt-4 col-6">
                    <img
                      src="../../../assets/images/equipment.png"
                      width="35%"
                    />
                    <div class="textField">Total Equipment</div>
                    <div class="textField count">
                      {{
                        equipmentCount?.total_equipments
                          ? equipmentCount?.total_equipments
                          : 0
                      }}
                    </div>
                  </div>
                </div>

                <!-- equipment content  -->
                <div class="mt-2">
                  <div class="d-flex flex-wrap mx-2">
                    <div
                      *ngFor="let data of equipmentLength; let i = index"
                      class="d-flex w-100 justify-content-between"
                    >
                      <div>
                        <span
                          class="color-box"
                          [style.background-color]="
                            baseColors[i % baseColors.length]
                          "
                        ></span>
                        <span class="textField ml-2">{{ data.title }}</span>
                      </div>
                      <span
                        style="margin-top: 6px"
                        class="textField ml-5 mr-3"
                        >{{ data.count }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Second Row -->
          <div class="d-flex second-row">
            <!-- General Task tab -->
            <div class="d-flex flex-column col-lg-5 col-md-12 mt-4" id="tabs">
              <div class="header">
                <h4>General Task</h4>
              </div>
              <div
                class="d-flex justify-content-between cursor-pointer canvas py-2"
                (click)="redirectDashboard('myTask')"
              >
                <div
                  class="d-flex flex-column col-6"
                  style="border-right: 1px solid darkgray"
                >
                  <div class="textField pt-1">Active Tasks</div>
                  <div class="totalCount">
                    {{
                      scheduleCount?.active_general_tasks
                        ? scheduleCount.active_general_tasks
                        : 0
                    }}
                  </div>

                  <div class="">
                    <img
                      src="../../../assets/images/activetask.png"
                      width="30%"
                      class="float-right mr-2"
                    />
                  </div>
                </div>

                <div class="d-flex flex-column col-6">
                  <div class="d-flex flex-column">
                    <span class="textField pt-1">Upcoming Task</span>
                    <span class="totalCount">{{
                      scheduleCount?.upcoming_general_tasks
                        ? scheduleCount.upcoming_general_tasks
                        : 0
                    }}</span>
                  </div>
                  <div class="">
                    <img
                      src="../../../assets/images/upcomingtask.png"
                      width="30%"
                      class="float-right"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Schedule tab -->
            <div class="d-flex flex-column col-lg-3 col-md-12 mt-4" id="tabs">
              <div class="header">
                <h4>Schedule</h4>
              </div>
              <div
                class="d-flex flex-column cursor-pointer canvas py-2"
                (click)="redirectDashboard('schedule')"
              >
                <div class="d-flex flex-column pl-2">
                  <span class="textField pt-1">Active Schedule</span>
                  <span class="totalCount">{{
                    scheduleCount?.active_Schedules
                      ? scheduleCount?.active_Schedules
                      : 0
                  }}</span>
                </div>
                <div>
                  <img
                    src="../../../assets/images/schedule.png"
                    width="23%"
                    class="float-right mr-3"
                  />
                </div>
              </div>
            </div>

            <!-- Repair and Maintenance -->
            <div class="d-flex flex-column col-lg-4 col-md-12 mt-4" id="tabs">
              <div class="header">
                <h4>Repair & Maintenance</h4>
              </div>
              <div class="d-flex canvas py-1" id="repair">
                <div
                  (click)="redirectDashboard('maintenance')"
                  class="cursor-pointer col-6"
                >
                  <div class="d-flex flex-column maintenance">
                    <img
                      src="../../../assets/images/maintenance.png"
                      width="45%"
                    />
                    <span class="textField">Total in R&M</span>
                    <span class="count">{{ maintenanceCount }}</span>
                  </div>
                </div>

                <!-- R&M content -->
                <div class="pt-2 col-6">
                  <div
                    class="d-flex justify-content-between maintenanceField"
                    *ngFor="let data of repair_maintenance; let i = index"
                  >
                    <div>
                      <span
                        class="color-box"
                        [style.background-color]="
                          baseColors[i % baseColors.length]
                        "
                      ></span>
                      <span class="textField"> {{ data.title }}</span>
                    </div>
                    <span class="textField repairCount">{{ data.count }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Third row -->
          <div class="d-flex flex-column col-12 mt-3" id="tabs">
            <div class="header ml-3">
              <h4>Backlog Summary</h4>
            </div>
            <div
              class="table-container cursor-pointer ml-3"
              (click)="redirectDashboard('schedule')"
            >
              <table class="table">
                <thead>
                  <th class="col-3">Backlog Summary</th>
                  <th class="col-3">Total</th>
                  <th class="col-3">Profit</th>
                  <th class="col-3">EST Profit Margin</th>
                </thead>
                <tbody *ngIf="summaryDetail && summaryDetail.length">
                  <tr *ngFor="let data of summaryDetail">
                    <td>{{ data?.status?.name }}</td>
                    <td>
                      {{ currencySymbol }}
                      {{
                        this.cos.convertToCurrency(
                          data?.totalInvoiced,
                          currencyDetails
                        )
                      }}
                    </td>
                    <td>
                      {{ currencySymbol }}
                      {{
                        this.cos.convertToCurrency(
                          data?.profit,
                          currencyDetails
                        )
                      }}
                    </td>
                    <td>{{ data?.profitPercentage }} %</td>
                  </tr>
                  <tr class="total" *ngFor="let item of summaryCount">
                    <td>Total</td>
                    <td>{{ item?.totalInvoiced }}</td>
                    <td>{{ item?.profit }}</td>
                    <td>{{ item?.profitPercentage }}%</td>
                  </tr>
                </tbody>
                <tbody *ngIf="summaryDetail && summaryDetail.length === 0">
                  <th class="text-center">No Summary</th>
                </tbody>
              </table>
            </div>
          </div>
        </div>


      <!-- Notifications column -->
    <div class="d-flex flex-column ml-1" id="notification">
      <div class="header">
        <h4>Notifications</h4>
      </div>

      <div style="overflow-y: auto; padding-top: 5px" class="canvas">
        <!-- Dropdown for selecting a group -->
        <app-drop-down 
          [formData]="modalForm"
          [dropDownData]="groupsData"
          (dropDownIds)="getGroupIds($event)" 
        ></app-drop-down>  

        <!-- Notification list -->
        <ng-container *ngFor="let notification of notifications; let i = index">
          <div
            class="d-flex notification my-3 cursor-pointer"
            (click)="redirectDashboard('broadcast-notification')"
          >
            <span
              class="block mx-2"
              [style.background-color]="baseColors[i % baseColors.length]"
            ></span>
            <div class="contents">
              <div class="title">{{ notification.title }}</div>
              <div class="description">{{ notification.description }}</div>
              <div class="date" style="font-size: 13px; color: #a5a5a5">
                {{ cos.getFormattedDate(notification.createdAt) }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- No notifications available -->
      <div
        style="overflow-y: auto; height: 80vh"
        class="canvas d-flex flex-column justify-content-center align-items-center"
        *ngIf="notifications && notifications.length === 0"
      >
        <img src="../../../assets/images/megaphone.png" width="30%" />
        <div>No Notifications</div>
      </div>

          <!-- Pagination -->
        <app-pagination
        (updatedList)="setPagination($event)"
        [paginationDetail]="completeData"
        ></app-pagination>
    </div>

        
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
