import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class VariableService {
  // Used in Loader services (jwt, error interceptors);
  apiCount = 0;

  // Used in Project estimates section to define the tabs
  tabNames = [
    {
      heading: "Estimate Type",
      values: ["Estimate Type", "Version", "Award"],
    },
    {
      heading: "Mobe Type",
      values: [
        "Mobe Type",
        "Mobe Intermediate Type",
        "Mobe Intermediate Measure",
      ],
    },
    {
      heading: "Lithology",
      values: [
        "Overburden",
        "Anticipated Condition",
        "Sedimentary",
        "Igneous",
        "Metamorphic",
      ],
    },
    {
      heading: "Safety",
      values: ["Document", "Certification"],
    },
    {
      heading: "Schedule",
      values: ["Schedule", "Crew"],
    },
    { heading: "Rig Restriction" },
    {
      heading: "Site",
      values: [
        "Site Type",
        "Site Caution",
        "Site Surface",
        "Staging Area",
        "Site Restriction",
        "Topography",
        "Cuttings",
        "Discharge Water",
        "Containment",
        "Site Schedule",
        "Recirculation",
        "Hoses",
        "Site Details",
      ],
    },
    {
      heading: "Consumables",
      values: [
        "Well Material",
        "Surface Completion",
        "Well Option",
        "Well Development",
        "Well Filler",
        "Consumable Option",
        "Grout Mix",
        "Sampling Supplies",
        "Down Holes",
        "Well Supplies"
      ],
    },
    {
      heading: "Abandonment",
      values: ["Abandonment Type", "Abandonment Option"],
    },
    {
      heading: "Water Source",
      values: [
        "Water Source Type",
        "Water Tanks",
        "Water Fill Fitting",
        "Water Cost",
      ],
    },
    {
      heading: "Sampling",
      values: ["Sampling Intervals", "Sampler", "Sampling Supplies"],
    },
    { heading: "PPE Details", values: ["PPE Level", "PPE Requirement"] },
    { heading: "Subcontractors" },
    { heading: "Drill-Table"},
    { heading: "Surface" },
    { heading: "Environmental" },
    { heading: "Time Consideration" },
    { heading: "Per Diem" ,
      values: ["Government Rates", "Custom Rates"],
    },
    { heading: "Permits" },
    { heading: "Reclamation" },
    { heading: "Fuel",
      values: ["Fuel", "Mobe Fuel", "Site Fuel"],
    },
    { heading: "Labor" },
    { heading: "Crew-Type" },
    { heading: "Client Request" },
    { heading: "Other Fees" },
    { heading: "Tax" },
    {
      heading: "Drill",
      values: ["Drill Overburden", "Drill Surface", "Drill Bedrock"],
    },
    {
      heading: "Support Equipment",
      values: [
        "Equipment Demo",
        "Equipment Night Work",
        "Equipment Accessories",
      ],
    },
    { heading: "Sizes" },
    { heading: "Rentals",
      values: [
        "Rentals",
        "Out source rentals",
      ]
    },
    { heading: "Miscellaneous" },
  ];

  samplingTabNames = [
    { heading: "Sampler" },
    { heading: "Sampling Interval" },
    { heading: "Sampling Supplies" },
  ];

  // Project Estimates Tabs
  estimateTabs = [
    "estimate-type",
    "rigs",
    "scope-notes",
    "mobe",
    "lithology",
    "site",
    "schedule",
    "surface",
    "drill-methods",
    "sampling-interval",
    "sampler",
    "sampling-supplies",
    "downhole-tests",
    "environmental",
    "installation",
    "abandonment",
    "reporting",
    "water-source",
    "support-equipment",
    "time-considerations",
    "per-diem",
    "client-request",
    "project-costs",
    "subcontractors",
    "permits",
    "rentals",
    "reclamation",
    "drill-table",
    "fuel",
    "labor",
    "crew-type",
    "safety",
    "additional-detail",
    "ppe",
    "tax",
    "miscellaneous"
  ];

  // Used in Daily report
  reportStatus = [
    { key: "WIP", value: "WIP" },
    { key: "MISSING", value: "Missing" },
    { key: "REVIEW", value: "Review" },
    { key: "APPROVED", value: "Approved" },
    { key: "INVOICED", value: "Invoiced" },
  ];

  // Used in common modal template to add button
  state = [
    {
      buttonName: "Save",
      type: "Modal",
    },
  ];

  // Used in common edit document modal
  documentModalButton = {
    fieldVisibility: "url",
    buttonData: { text: "Save" },
  };

  // Used in Load list module
  templateCategory = [
    { key: "job specific lists", value: "Job Specific Lists" },
    { key: "job type lists", value: "Job Type Lists" },
    { key: "", value: "Clear" },
  ];

  // Used in field inspection
  choices = [
    { key: "is_good", value: "Good" },
    { key: "is_fixed_in_field", value: "Temp fix" },
    { key: "is_shop_fixed", value: "Shop Fix" },
  ];

  // Used to change the status of leave in off time
  status = [
    { value: "Approved", key: "APPROVED" },
    { value: "Pending", key: "PENDING" },
    { value: "Rejected", key: "REJECTED" },
  ];

  // Used in time off slots
  timeSlot = [
    { key: "FIRST-SHIFT", value: "First Shift" },
    { key: "SECOND-SHIFT", value: "Second Shift" },
    { key: "THIRD-SHIFT", value: "Third Shift" },
    { key: "FULL-DAY", value: "Full Day" },
  ];

  // Used in time off slots
  leaveType = [
    { key: "PAID", value: "Paid" },
    { key: "UNPAID", value: "Unpaid" },
  ];

  // Variable used in search input
  searchValue = "";

  // Used in R&M section
  statusData = [
    { key: "OPEN", value: "Open" },
    { key: "TEMP_FIX", value: "Temporary Fix" },
    { key: "COMPLETE", value: "Complete" },
    { key: "SCHEDULED", value: "Schedule" },
  ];

  // Used in task list/ my task
  occurringData = [
    { key: "NO_REPEAT", value: "Don't Repeat" },
    { key: "DAILY", value: "Daily" },
    { key: "WEEKLY", value: "Week" },
    { key: "BI_WEEKLY", value: "Bi Weekly" },
    { key: "MONTHLY", value: "Month" },
    { key: "QUARTERLY", value: "Quarter" },
    { key: "HALF_YEARLY", value: "Half Year" },
    { key: "YEARLY", value: "Year" },
  ];

  // used in project -> meet
  meetingMode = [
    { key: "ONLINE", value: "Online" },
    { key: "OFFLINE", value: "Offline" },
  ];

  // used in project -> meet
  meetingType = [
    { key: "ONCE", value: "Don't Repeat" },
    { key: "DAILY", value: "Every Day" },
    { key: "WEEKLY", value: "Every Week" },
    { key: "MONTHLY", value: "Every Month" },
    { key: "YEARLY", value: "Every Year" },
  ];

  // Used in schedule
  jobTypeValue = [
    { key: "INDIVIDUAL", value: "Individual" },
    { key: "COMBINED", value: "All Shifts" },
  ];

  // Used in employee status
  employeeStatus = [
    { key: "ACTIVE", value: "Active" },
    { key: "LEFT", value: "Left" },
    { key: "FIRED", value: "Fired" },
    { key: "IN_ACTIVE", value: "Inactive" },
  ];

  // Used in s reviewer section to define the tabs
  reviewerTabs = [
    {
      heading: "Rigs",
    },
    {
      heading: "Mobe",
    },
    {
      heading: "Site",
    },
    {
      heading: "Schedule",
    },
    {
      heading: "Drill-Table",
    },
    {
      heading: "Drill",
    },
    {
      heading: "Sampling",
    },
    {
      heading: "Consumables",
    },
    {
      heading: "DownHole Tests",
    },
    {
      heading: "Environmental",
    },
    {
      heading: "Support Equipment",
    },
    {
      heading: "Decon",
    },
  ];

  // START: Reviewer SubTyp Lists
  rigsList = [
    { key: "_rig", status: "", value: "Rig" },
    { key: "_angle", status: "", value: "Angle" },
    { key: "_sonic", status: "", value: "Sonic" },
    {
      key: "_explorationDrilling",
      status: "",
      value: "Exploration Drilling",
    },
  ];
  mobeList = [
    { key: "_abandonment", status: "", value: "Abandonment/Load" },
    { key: "_restockSupplies", status: "", value: "Restock Supplies" },
  ];
  siteList = [{ key: "_subSite", status: "", value: "Site" }];
  scheduleList = [
    {
      key: "_schedulingConsiderations",
      status: "",
      value: "Scheduling Considerations",
    },
    { key: "_cdlHours", status: "", value: "CDL Hours" },
    { key: "_siteHours", status: "", value: "Site Hours" },
    { key: "_nightShift", status: "", value: "Night Shift" },
    { key: "_crews", status: "", value: "Crews" },
  ];
  drillTableList = [
    {
      key: "_airCompressorSize",
      status: "",
      value: "Air Compressor Size",
    },
    { key: "_tooling", status: "", value: "Tooling" },
  ];
  drillList = [
    {
      key: "_drillMethodSelectionConsiderations",
      status: "",
      value: "Drill Method Selection Considerations",
    },
    {
      key: "_formationKeyTriggerWords",
      status: "",
      value: "Formation Key Trigger Words",
    },
    {
      key: "_airCompressorSize",
      status: "",
      value: "Air Compressor Size",
    },
    { key: "_samplers", status: "", value: "Samplers" },
    { key: "_installations", status: "", value: "Installations" },
    { key: "_downholeTesting", status: "", value: "Downhole Testing" },
    { key: "_AUGER", status: "", value: "AUGER" },
    { key: "_CASING_ADVANCER", status: "", value: "CASING ADVANCER" },
    { key: "_AIR_ROTARY", status: "", value: "AIR ROTARY" },
    { key: "_CORING", status: "", value: "CORING" },
    { key: "_AIR_CORING", status: "", value: "AIR CORING" },
    { key: "_ODEX", status: "", value: "ODEX" },
    { key: "_MUD_ROTARY", status: "", value: "MUD ROTARY" },
    { key: "_SITE_ACTIVITIES", status: "", value: "SITE ACTIVITIES" },
    { key: "_LABOR", status: "", value: "LABOR" },
    {
      key: "_DEEPER_CORE_PROJECTS",
      status: "",
      value: "DEEPER CORE PROJECTS",
    },
  ];
  samplingList = [{ key: "_samplers", status: "", value: "Samplers" }];
  consumablesList = [
    { key: "_ABANDONMENT", status: "", value: "ABANDONMENT" },
    { key: "_stateRules", status: "", value: "State Rules" },
    { key: "_INSTRUMENTATION", status: "", value: "INSTRUMENTATION" },
  ];
  downholeTestsList = [
    { key: "_subDownHoleTests", status: "", value: "DownHole Tests" },
  ];
  environmentalList = [{ key: "_decon", status: "", value: "Decon" }];
  supportEquipmentList = [
    { key: "_skidsteer", status: "", value: "Skidsteer" },
    { key: "_mudPuppy", status: "", value: "Mud Puppy" },
  ];
  deconList = [{ key: "_decon", status: "", value: "Decon" }];
  // END: Reviewer SubTyp Lists
}
