import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Message } from '../interfaces/common-interface';
import { GroupList, GroupResponse, GroupDetail, Activity , GroupCheck} from '../interfaces/group';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  GROUP_LIST = 'aggregation/groups/list';
  CRUD_GROUP = 'aggregation/groups';
  GROUP_LOG = 'aggregation/activity-logs';
  GROUP_ACTIVITY = 'aggregation/groups/check';
  GROUP_NOTIFICATION = 'aggregation/notifications/group';

  constructor(private http: HttpClient) { }

  getList(search) {
    return this.http.get<GroupList>(`${environment.BASE_URL}${this.GROUP_LIST}?search=${search.search}&pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}`);
  }

  getGroupList() {
    return this.http.get<GroupList>(`${environment.BASE_URL}${this.GROUP_LIST}?pagination=false`);
  }

  createGroup(data) {
    return this.http.post<GroupResponse>(environment.BASE_URL + this.CRUD_GROUP, data);
  }

  groupNotification(data) {
    return this.http.post<GroupList>(environment.BASE_URL + this.GROUP_NOTIFICATION, data);
  }
  
  updateGroup(data) {
    return this.http.put<Message>(environment.BASE_URL + this.CRUD_GROUP, data);
  }

  deleteGroup(data) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: data,
    };
    return this.http.delete<Message>(environment.BASE_URL + this.CRUD_GROUP, options);
  }

  groupDetails(id: string) {
    return this.http.get<GroupDetail>(`${environment.BASE_URL}${this.CRUD_GROUP}?id=${id}`);
  }
  
  groupLogs(id: string) {
    return this.http.get<Activity>(`${environment.BASE_URL}${this.GROUP_LOG}?id=${id}&type=GROUP`);
  }

  checkGroupActivity(id) {
    return this.http.get<GroupCheck>(`${environment.BASE_URL}${this.GROUP_ACTIVITY}?groupId=${JSON.stringify(id)}`);
  }

}
