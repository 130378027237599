import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { RepairMaintenanceComponent } from 'src/app/modules/repair-maintenance/repair-maintenance.component';
import { CommonService } from 'src/app/_services/common.service';
import { UntypedFormBuilder } from '@angular/forms';
import 'bootstrap';
import { FunctionsService } from 'src/app/_common/functions.service';
import { response } from 'src/app/enum/message';
import { Search } from 'src/app/interfaces/common-interface';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnChanges {
  @ViewChild('childMenu', { static: true }) public childMenu;
  @Input() items = [];
  @Input() modalId = 'filter';
  @Input() filtersData = [];
  @Input() state: string;
  @Input() dateFilter = false;
  @Input() taskDates = [];
  @Input() singleSelection = false;
  @Input() width = 'medium';
  @Input() fieldWidth = false;
  @Input() formData = [];
  @Input() modalForm;
  @Input() smallSizeField = false;
  @Input() largeSizeField = false;
  @Output() drilledStatusChange = new EventEmitter<boolean>();

  @Output() filterChild = new EventEmitter();
  @ViewChildren(RepairMaintenanceComponent) filter: RepairMaintenanceComponent;
  filterValue = [];
  showStatus = [false];
  selectedData = [];
  defaultData = [];
  showPriority = [false];
  clearValue = false;
  selectedOption: string;
  originalItems = [];
  searchInput: string[] = [].fill('');
  filterSearch: string;
  filterValSearch: string;
  originalData: Search;
  count = 0;
  searchRecord: string;
  filteredList = [];
  childFilter = [];
  originalList = [];
  filterName: string;
  color = "blue";
  modalProperty = {
    header: 'Add Filter',
    id: 'filter'
  };
  checkboxStates: { [key: string]: boolean } = {};
  @Input() maxDate: Date = null;

  constructor(
    private fb: UntypedFormBuilder,
    public cos: CommonService,
    private commonFunction: FunctionsService,
  ) {
  }

  dateForm = this.fb.group({
    startDate: '',
    endDate: ''
  });
  ngOnChanges() {
    this.dateForm.reset();
    this.originalItems = this.items;
    this.searchInput = [];

    if (this.taskDates.length && this.taskDates[0].startDate && this.taskDates[0].endDate) {
        this.dateForm.patchValue({
            startDate: this.taskDates[0].startDate,
            endDate: this.taskDates[0].endDate
        });
    }

    this.filteredList = this.items;
    this.childFilter = this.items;
    this.originalList = this.items;
    this.dateFilter = false;
    this.filterName = this.items.length ? this.items[0].displayName : '';

    // Load checkbox states from local storage if available
    const savedCheckboxStates = localStorage.getItem('checkboxStates');
    if (savedCheckboxStates) {
        this.checkboxStates = JSON.parse(savedCheckboxStates);
    }

    this.isExistFilter(this.filtersData);
}


  ngOnDestroy() {
    // Clear local storage for checkbox states when leaving this component
    localStorage.removeItem('checkboxStates');
  }
    
  private updateCheckboxStates() {
    if (this.childFilter && this.childFilter.length) {
      this.childFilter[0].children.forEach(child => {
        if (this.checkboxStates[child.id] === undefined) {
          this.checkboxStates[child.id] = false;
        }
      });
    }
  }  
  
  private isExistFilter(data) {
    if (data) {
      if (Array.isArray(data) && data.length === 0) {
        return false;
      }
      else if (data[0].value.length === 0) {
        return false;
      }
      else {
        if (!this.clearValue) {
          const statusLength = data[0].value.length;
          this.showStatus = new Array(statusLength).fill(true);
          if (data[1]) {
            const priorityLength = data[1].value.length;
            this.showPriority = new Array(priorityLength).fill(true);
          }
        }
        return true;
      }
    }
  }

  private clrFilter(index, item, type, ids) {
    this.clearValue = true;
    if (type === 'Status') {
      this.showStatus.map(() => true);
      this.showStatus[index] = false;
    } else {
      this.showPriority.map(() => true);
      this.showPriority[index] = false;
    }
    this.filterValue.push({ check: index, id: (Array.isArray(ids) && ids.length) ? ids[index] : item, type: type, state: this.state });
  }

   // This function provide filter value which we selected
   public toggle(item, type: string) {

    const isChecked = this.checkboxStates[item.id] || false; // Default to false if undefined
    this.checkboxStates[item.id] = !isChecked;
    if (this.checkboxStates[item.id]) {
        this.filterValue.push({
            id: item.id,
            type: type,
            state: this.state,
            dataType: item.type
        });
    } else {
        this.filterValue = this.filterValue.filter(filter => filter.id !== item.id);
    }

    // Save to local storage
    localStorage.setItem('checkboxStates', JSON.stringify(this.checkboxStates));
    localStorage.setItem('filterValue', JSON.stringify(isChecked));
    
 }
   
  private getCheck(data) {
    let check = [];
    if (data) {
      data.map((val) => {
        if (val.title === 'priority')
          check.push(val.value);
      });
      check = check.flat();
      if (check.length) {
        return true;
      } else {
        return false;
      }
    }
  }

  private getCheckData(data) {
    let check = [];
    if (data) {
      data.map((val) => {
        if (val.title === 'status')
          check.push(val.value);
      });
      check = check.flat();
      if (check.length) {
        return true;
      } else {
        return false;
      }
    }
  }

  // This function specify the operation when drop down open
  triggerEvent(data: boolean, fieldName: string, arr) {
    this.count = 0;
    if (!data) {
      this.formData.map((val) => {
        if (val.name === fieldName) {
          val.multipleValues = this.originalData && this.originalData.name === fieldName ? this.originalData.value : arr;
        }
      });
      this.searchRecord = '';
    }
  }

   // This function is used to set the width of fields
   setWidth() {
    return this.fieldWidth ? 'col-4' : (this.smallSizeField ? 'col-3' : 'col-6');
  }

  onToggleChange(event: any) {
    const isChecked = event.checked;
    if (isChecked) {
      this.drilledStatusChange.emit(true);
    } else {
      this.drilledStatusChange.emit(false);
    }
  }

  // Function to submit value for filter
  public submitData(type: string) {
    
    if (type === 'reset') {
      
      this.filterValue = [];
      this.dateForm.patchValue({
        startDate: '',
        endDate: ''
      });
  
      // Reset checkbox states
      this.resetCheckboxStates();
      localStorage.removeItem('checkboxStates');
  
      // Reset other filters and related variables
      this.filteredList = this.originalItems;
      this.childFilter = this.originalItems;
      this.filterSearch = '';
      this.filterValSearch = '';
      this.filterName = this.items.length ? this.items[0].displayName : '';

      this.updateCheckboxStates();

      // Emit reset event to notify other components if necessary
      this.filterChild.emit({
        filters: this.filterValue.filter(item => this.checkboxStates[item.id]), 
        date: this.dateForm.value,
        type: this.state
      });
      this.commonFunction.modalClose(this.modalProperty);
      // Exit the function after resetting
      return;
  
    }
  
    if ((this.dateForm.value.startDate && !this.dateForm.value.endDate) || (!this.dateForm.value.startDate && this.dateForm.value.endDate)) {
      return this.cos.toastMessage(response.en.ADD_BOTHDATES, 'warning');
    } else if (new Date(this.dateForm.value.startDate).valueOf() > new Date(this.dateForm.value.endDate).valueOf()) {
      return this.cos.toastMessage(response.en.START_END, 'warning');
    } else {
      // Filter out deselected items from filterValue array before sending payload
      const filters = {
        filters: this.filterValue,
        date: this.dateForm.value,
        type: this.state,
      };
  
      this.filterChild.emit(filters);
      this.commonFunction.modalClose(this.modalProperty);
    }
  }
  
  private resetCheckboxStates() {
    // Reset checkbox states
    this.checkboxStates = {}; 
  }
  

  private selectFilter(data) {
    this.selectedData.push(data.displayName);
    for (let i = 0; i < this.filtersData.length; i++) {
      this.filtersData[i].value.map((val) => {
        this.defaultData.push(val);
      });
    }
    this.selectedData = [...new Set(this.selectedData)];
    this.defaultData = [...new Set(this.defaultData)];
    this.selectedData.map((item) => {
      return this.defaultData.includes(item);
    });

  }

  // Search functionality
  private searchData(input, index: number) {
    const search = input.target.value
    if (search || search.trim().length !== 0) {
      const filteredData = this.items[index].children.filter((val) =>
        val.displayName.toLowerCase().includes(search.toLowerCase())
      );
      this.items[index].children = filteredData;
    } else {

      this.items = this.originalItems;

    }
  }

  public selectItem(data) {
    this.filterName = data.displayName;
    if (data.displayName.includes('Dates')) {
      this.dateFilter = true;
      this.childFilter = [];
      this.originalList = [];
    } else {
      this.dateFilter = false;
      this.childFilter = [data];
      this.originalList = [data];
    }
  }

  // Function to search filter children
  public searchDelay(value: string, type: string) {
    if (type === 'filterName') {
      if (this.filterSearch === '') {
        this.items = this.filteredList;
      } else {
        this.items = this.items.filter(item =>
          item.displayName.toLowerCase().includes(value.toLowerCase())
        );
      }
  
    } else {
      if (this.filterValSearch === '') {
        this.childFilter = this.originalList;
      } else {
        this.childFilter = this.originalList.map(item => this.filterByDisplayName(item, value));
        this.childFilter.sort((a, b) => {
          if (a.displayName === this.filterName) return -1;
          if (b.displayName === this.filterName) return 1;
          return 0;
        });
      }
    }
  }
  
  // Function to filter data based on filter's children name
  public filterByDisplayName(item, displayName: string) {
    if (item.children) {
      return {
        ...item,
        children: item.children.filter(child => child.displayName.toLowerCase().includes(displayName.toLowerCase()))
      };
    }
  }  

  // Function to compare two value for highlight the color of selected filter
  private activesStatus(type: string) {
    if (type) {
      const filterType = type.replace(/\(\d+\)$/, '');
      return filterType === this.filterName;
    }
  }

}

