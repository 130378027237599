<div *ngIf="isConsumables" class="heading">
  Total Fluids and Consumables : <b>${{ totalCost | number:'1.2-2' }}</b>
</div>

<div class="root-container">
  <div class="table-responsive my-2 tableEffect">
    <table class="table rounded-3">
      <thead class="thead bg-header fixedHeading pl-2 ad-text-size">
        <tr>
          <th *ngFor="let column of columns">{{ column.name }}</th>
        </tr>
      </thead>
      <tbody class="ad-text-size">
        <tr *ngFor="let row of orderList; let i = index">
          <th scope="row">{{ row.item }}</th>
          <td *ngIf="columnVisible('Quantity')">
            <input min="0" matInput class="ad-text-size text-center input-field" type="number"
              [(ngModel)]="row.quantity" maxlength="3" />
          </td>
          <td *ngIf="columnVisible('Cost')">
            <input min="0" matInput class="ad-text-size text-center input-field" type="number" [(ngModel)]="row.cost"
              maxlength="3" [disabled]="true" />
          </td>
          <td *ngIf="columnVisible('Selling Cost')">
            <input min="0" matInput class="ad-text-size text-center input-field" type="number"
              [(ngModel)]="row.sellingCost" maxlength="3" [disabled]="true" />
          </td>
          <td *ngIf="columnVisible('Unit')">
            <select class="ad-text-size text-center dropdown" [(ngModel)]="row.option" [disabled]="row.disabled">
              <option *ngFor="let option of options" [value]="option">{{ option }}</option>
            </select>
          </td>
          <td *ngIf="isRentals && columnVisible('Unit Quantity')">
            <input min="0" matInput class="ad-text-size text-center input-field" type="number"
              [(ngModel)]="row.unitQuantity" maxlength="3" />
          </td>
          <td *ngIf="isRentals && columnVisible('Fuel Required')">
            <mat-slide-toggle [(ngModel)]="row.isFuelRequired" (change)="onToggleChange($event, i)"></mat-slide-toggle>
          </td>
          <td *ngIf="columnVisible('Total Cost')">{{ row.totalCost }}</td>
          <td *ngIf="columnVisible('Total Selling Cost')">{{ row.totalSellingCost }}</td>
        </tr>
        <tr class="row-bg">
          <th scope="row">Total</th>
          <td *ngIf="columnVisible('Quantity')"></td>
          <td *ngIf="columnVisible('Cost')"></td>
          <td *ngIf="columnVisible('Selling Cost')"></td>
          <td *ngIf="columnVisible('Unit')"></td>
          <td *ngIf="isRentals && columnVisible('Unit Quantity')"></td>
          <td *ngIf="isRentals && columnVisible('Fuel Required')"></td>
          <td></td>
          <td>{{ totalCost }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <button class="btn btn-primary float-right mx-3 my-4 margin-bottom" (click)="updateRecord('SAVE')">Save</button>
    <button class="btn btn-primary float-right mx-3 my-4 margin-bottom" (click)="updateRecord('SAVE&BACK')">Save &
      Back</button>
    <button class="btn btn-primary float-right mx-3 my-4 margin-bottom" (click)="onFetchUpdatedCost()">Fetch Updated
      Cost</button>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion"
  [fullScreen]="true"></ngx-spinner>